// --- Components ---
const WcBookings = {

    readyFn: function () {
        let self = this;
        self.WcBookingsPersonsSpinner();
        self.addClassForm();
    },

    WcBookingsPersonsSpinner: function () {

        // Je ne trouve pas de moyen de mettre à jour le prix total après ça, donc on laisse tomber
        // document.addEventListener('click', function (event) {

        //     event.preventDefault();

        //     if (!event.target.matches('button.minus:not(.disabled), button.plus:not(.disabled)')) return;

        //     var clicked = event.target,
        //         field = clicked.closest('p').querySelector('input#wc_bookings_field_persons'),
        //         minus = clicked.closest('p').querySelector('button.minus'),
        //         plus = clicked.closest('p').querySelector('button.plus'),
        //         cur = field.value,
        //         step = parseInt(field.getAttribute('step')),
        //         min = parseInt(field.getAttribute('min')),
        //         max = parseInt(field.getAttribute('max')),
        //         qty = (clicked.classList.contains('minus')) ? parseInt(cur) - step : parseInt(cur) + step,
        //         correct_qty = (qty < min) ? min : qty;

        //     if (correct_qty <= min) {
        //         minus.classList.add('disabled');
        //     } else {
        //         minus.classList.remove('disabled');
        //     }
        //     if (correct_qty >= max) {
        //         plus.classList.add('disabled');
        //     } else {
        //         plus.classList.remove('disabled');
        //     }

        //     field.value = correct_qty;

        //     // TODO : trigger un change pour mise à jour du prix total

        // });
    },

    addClassForm: function () {
        //console.log("addClassForm");
        if ($("#wc-bookings-booking-form").length > 0) {
            $("form.cart").addClass("js-booking-form");
            //console.log("tourte au poulet");
        }
    },


}
export {
    WcBookings
};