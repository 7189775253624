// --- Components ---
const menu = {

    readyFn: function () {
        let self = this;
        self.menu();
        self.menuMobile();
        self.menuMobileFooter();
        self.noLinkMenu();
    },

    menu: function () {
        $(".menu-button-header").on("click", function () {
            $("#wrapper-menu-desktop").toggleClass("actif");
            $(this).toggleClass("actif");
            if ($(this).hasClass("actif")) {
                $(this).text("Fermer");
            } else {
                $(this).text("Menu");
            }
        });
    },

    menuMobile: function () {
        $(".menu-button-mobile").on("click", function () {
            $("#wrapper-menu-mobile").toggleClass("actif");
            $("#page-content").toggleClass("menu-mobile-actif");
            $(this).toggleClass("actif");
            if ($(this).hasClass("actif")) {
                $(this).text("Fermer");
            } else {
                $(this).text("Menu");
            }
        });

        $("#wrapper-menu-mobile .sub-menu").slideUp();

        $("#wrapper-menu-mobile .menu-item-has-children").on("click", function () {
            if ($(this).hasClass("sub-menu-actif")) {
                $(".sub-menu", this).slideUp();
                $(this).removeClass("sub-menu-actif");
            } else {
                $(".sub-menu").slideUp();
                $(".sub-menu", this).slideDown();
                $(".menu-item-has-children").removeClass("sub-menu-actif");
                $(this).addClass("sub-menu-actif");

            }
        })
    },

    menuMobileFooter: function () {
        $("footer .cell-menu-mobile .sub-menu").slideUp();

        $(".cell-menu-mobile .menu-item-has-children").on("click", function () {
            if ($(this).hasClass("sub-menu-actif")) {
                $(".sub-menu", this).slideUp();
                $(this).removeClass("sub-menu-actif");
            } else {
                $(".sub-menu", this).slideUp();
                $(".sub-menu", this).slideDown();
                $(".menu-item-has-children", this).removeClass("sub-menu-actif");
                $(this).addClass("sub-menu-actif");

            }
        })
    },

    noLinkMenu: function () {
        $(".menu-item-has-children > a").each(function () {
            if ($(this).attr('href') == null) {
                $(this).addClass('no-link');
            }
        });
    }


}
export {
    menu
};