// --- Vendors ---
import AOS from 'aos';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);


// --- Components ---
const homePage = {
    readyFn: function () {
        let self = this;
        self.swiperHome();
        self.playSchema();
    },
    swiperHome: function () {
        // init Swiper:
        const swiperEvenement = new Swiper('.swiper-evenement', {
            // configure Swiper to use modules
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1.1,
            spaceBetween: 20,
            breakpoints: {
                800: {
                    slidesPerView: 3.3,
                    spaceBetween: 50,
                }
            },
            freeMode: true,
        });
    },

    playSchema: function () {
        var $win = $(window);
        var elementTop, elementBottom, viewportTop, viewportBottom;

        function isScrolledIntoView(elem) {
            elementTop = $(elem).offset().top;
            elementBottom = elementTop + $(elem).outerHeight();
            viewportTop = $win.scrollTop();
            viewportBottom = viewportTop + $win.height() - 400;
            return (elementBottom > viewportTop && elementTop < viewportBottom);
        }

        $win.scroll(function () { // video to play when is on viewport 

            $('#lottie-video').each(function () {
                if (isScrolledIntoView(this) == true) {
                    $(this)[0].play();
                }
            });

        }); // video to play when is on viewport

        $('body').on('touchmove', function () {
            $('#lottie-video').each(function () {
                if (isScrolledIntoView(this) == true) {
                    $(this)[0].play();
                }
            });
        })

    }
}
export {
    homePage
};