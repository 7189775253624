// --- Vendors ---
import AOS from 'aos';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);


// --- Components ---
const pisteKarting = {
    readyFn: function () {
        let self = this;
        self.swiperPiste();
        self.playSchema();
    },
    swiperPiste: function () {
        // init Swiper :
        const swiper = new Swiper('.swiper', {
            // configure Swiper to use modules
            modules: [Pagination],
            pagination: {
                pagination: true,
                el: '.swiper-pagination',
                paginationClickable: true,
            },
            slidesPerView: 1.1,
            spaceBetween: 20,
            freeMode: false,
            centeredSlides: true,
            breakpoints: {
                800: {
                    slidesPerView: 2.5,
                    spaceBetween: 40,
                }
            },

        });
    },

    playSchema: function () {
        var $win = $(window);
        var elementTop, elementBottom, viewportTop, viewportBottom;

        function isScrolledIntoView(elem) {
            elementTop = $(elem).offset().top;
            elementBottom = elementTop + $(elem).outerHeight();
            viewportTop = $win.scrollTop();
            viewportBottom = viewportTop + $win.height() - 400;
            return (elementBottom > viewportTop && elementTop < viewportBottom);
        }

        $win.scroll(function () { // video to play when is on viewport 

            $('#lottie-video').each(function () {
                if (isScrolledIntoView(this) == true) {
                    $(this)[0].play();
                }
            });

        }); // video to play when is on viewport

        //Play on phone
        $('body').on('touchmove', function () {
            $('#lottie-video').each(function () {
                if (isScrolledIntoView(this) == true) {
                    $(this)[0].play();
                }
            });
        })

    }
}
export {
    pisteKarting
};