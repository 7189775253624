// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);
// --- Components ---
const cameleon = {
    readyFn: function () {
        let self = this;
        self.wpButton();
        self.wpListe();
        this.swapToSvg();
    },

    wpButton: function () {
        $("section.cameleon-block .wp-block-button .wp-element-button").prepend("<img class='svg' src='/wp-content/uploads/2023/01/fast-forward.svg' alt='Icon double flèche'>");
        $("section.cameleon-block-acf-grid .cell.item .button").prepend("<img class='svg' src='/wp-content/uploads/2023/01/fast-forward.svg' alt='Icon double flèche'>");

    },

    wpListe: function () {
        $("section.cameleon-block li").prepend("<img class='svg' src='/wp-content/uploads/2023/01/single-arrow-primary.svg' alt='Icon simple flèche'>");
    },

    swapToSvg: function () {

        //SVG
        jQuery('img.svg').each(function () {
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

    }


}
export {
    cameleon
};