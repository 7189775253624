// --- Vendors ---
import AOS from 'aos';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);


// --- Components ---
const singleProduct = {
    readyFn: function () {
        let self = this;
        self.addClassForm();
    },

    addClassForm: function () {
        console.log("zijeriuzegrgzruuyeru");
    }
}
export {
    singleProduct
};