// --- Vendors ---
import AOS from 'aos';
import Isotope from 'isotope-layout';
import Masonry from 'masonry-layout';
var imagesLoaded = require('imagesloaded');
var jQueryBridget = require('jquery-bridget');


// --- Components ---
const partenaires = {
    readyFn: function () {
        let self = this;

        jQueryBridget('isotope', Isotope, $);

        self.isotopeSection();
    },

    isotopeSection: function () {
        //ISOTOPE ---------------

        $(".tag").on("click", function () {
            $(".tag").removeClass("actif");
            $(this).addClass("actif");
        });

        var $gridPartenaire = $('.grid-partenaire').isotope({
            itemSelector: '.cell-partenaire',
            layoutMode: 'fitRows'
        });

        $(".tag-list").on("click", ".tag", function () {
            var filterValue = $(this).attr("data-filter");
            $gridPartenaire.isotope({
                filter: filterValue
            });
        });

        $("#filtre-partenaire").on("change", function () {
            var filterValue = $(this).val();
            console.log();
            $gridPartenaire.isotope({
                filter: filterValue
            });

            if ($("#filtre-partenaire option:selected").val() == ".hebergement") {
                $("#filtre-hebergement").slideDown();
            } else $("#filtre-hebergement").slideUp();
        });

        $("#filtre-hebergement").on("change", function () {
            var filterValue = $(this).val();
            $gridPartenaire.isotope({
                filter: filterValue
            });
        });

        let curentURL = window.location.href;
        if (curentURL.search('#') != -1) {
            $(".tag-list .actif").removeClass("actif");
            let link = curentURL.split('#')[1].split('/')[0];
            $gridPartenaire.isotope({
                filter: link
            });
        }

        $(".tag-list-etoile").hide();
        $(".tag-list").on("click", ".tag", function () {
            if ($(".tag-hebergement").hasClass("actif")) {
                $(".tag-list-etoile").slideDown();
            } else {
                $(".tag-list-etoile").slideUp();
            }
        });

        $(".tag-list-etoile").on("click", ".tag", function () {
            var filterValue = $(this).attr("data-filter");
            $gridPartenaire.isotope({
                filter: filterValue
            });
        });

    }
}
export {
    partenaires
};

