// --- Vendors ---
import AOS from 'aos';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);


// --- Components ---
const devis = {
    readyFn: function () {
        let self = this;
        self.precisionDevis();
    },

    precisionDevis: function () {
        $(".wrapper-precision-repas").hide();
        if ($("input#oui-repas").is(":checked")) {
            $(".wrapper-precision-repas").show();
        }
        $("input[name=repas]").on("change", function () {
            if ($("input#oui-repas").is(":checked")) {
                $(".wrapper-precision-repas").slideDown();
            } else {
                $(".wrapper-precision-repas").slideUp();
            }
        });
    }
}
export {
    devis
};