// --- Components ---
const bodyPaddingTop = {

    readyFn: function () {
        let self = this;
        self.menuMobilePaddingTop();
    },

    menuMobilePaddingTop: function () {
        var widthScreen = jQuery(window).width();
        var headerHeight = document.getElementById('menu-mobile').offsetHeight;
        if (widthScreen < 1200) {
            $("#page-content").css("padding-top", headerHeight + "px");
        }
    }


}
export {
    bodyPaddingTop
};