// --- Vendors ---
import AOS from 'aos';

// --- Components ---
const toutEvenement = {
    readyFn: function () {
        let self = this;
        self.tri();
    },

    tri: function () {
        $("#tri-all").on("click", function () {
            $(this).addClass("actif");
            $(".single-cell").fadeIn(400);
        });
        $("#tri-karting").on("click", function () {
            $(this).addClass("actif");
            $(".single-cell").fadeOut(400);
            $(".single-cell.karting").delay(500).fadeIn();
        });

        $("#tri-circuit").on("click", function () {
            $(this).addClass("actif");
            $(".single-cell").fadeOut(400);
            $(".single-cell.circuit").delay(500).fadeIn();
        })
    }
}
export {
    toutEvenement
};