// --- Vendors ---

// --- Components ---
const visibilite = {
    readyFn: function () {
        let self = this;
        self.showInfo();
        self.showInfoMobile();
    },
    showInfo: function () {
        $("#visibilite .single-repeteur-intitule").on("click", function () {
            $(".single-repeteur-intitule").removeClass("actif")
            $(this).addClass("actif");
            let info = $(this).attr("data-info");
            $(".cell-information").removeClass("actif");
            $(info).addClass("actif");
            console.log(info);
        });
    },

    showInfoMobile: function () {
        $("#visibilite .single-repeteur-intitule-mobile").on("click", function () {
            if ($(this).hasClass("actif")) {
                $(".cell-information-mobile").slideUp();
                $(this).removeClass("actif");
            } else {
                $(".single-repeteur-intitule-mobile").removeClass("actif");
                $(this).addClass("actif");
                let info = $(this).attr("data-info");
                $(".cell-information-mobile").slideUp();
                $(info + "-mobile").slideDown();
            }
        });
    },

}
export {
    visibilite
};