// --- Vendors ---
import AOS from 'aos';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);

// --- Components ---
const espace = {
    readyFn: function () {
        let self = this;
        self.espace();
        self.swiperEspace();
    },

    espace: function () {
        console.log("test espace");
        $("#legende-carte .groupe").on("click", function () {
            $("#legende-carte .groupe").removeClass("actif");
            $(this).addClass("actif");

            let index = $(this).attr("data");
            $(".cell-espace .wrapper-espace").removeClass("actif");
            $(".cell-espace .espace-number-" + index).delay(400).addClass("actif");
        });

        $(".cell-espace-responsive").slideUp();
        $("select#select-espace").on("change", function () {
            $(".cell-espace-responsive").slideUp(300);
            let valueSelect = $(this).val();
            $("#espace-responsive-" + valueSelect).delay(400).slideDown();
        });
    },

    swiperEspace: function () {
        // init Swiper :
        const swiper = new Swiper('.swiper', {
            // configure Swiper to use modules
            modules: [Pagination],
            pagination: {
                pagination: true,
                el: '.swiper-pagination',
                paginationClickable: true,
            },
            slidesPerView: 1.1,
            spaceBetween: 20,
            breakpoints: {
                800: {
                    slidesPerView: 2.5,
                    spaceBetween: 40,
                }
            },
            freeMode: true,
        });
    },
}
export {
    espace
};