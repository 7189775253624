// --- Vendors ---
import Glightbox from 'glightbox';
// --- Components ---
const contact = {
    readyFn: function () {
        let self = this;
        self.revealPlan();
    },
    revealPlan: function () {
        const glightbox = Glightbox();
    },
}
export {
    contact
};