// Libs
import $ from 'jquery';
window.$ = window.jQuery = $;

import { Foundation } from 'foundation-sites/js/foundation.core';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.addToJquery($);
Foundation.plugin(Reveal, 'Reveal');

import Swiper from 'swiper';
import Glightbox from 'glightbox';
import AOS from 'aos';

// Import pages
import {
    homePage
} from './pages/home';
import {
    securite
} from './pages/securite';
import {
    piste
} from './pages/piste';
import {
    pisteKarting
} from './pages/pisteKarting';
import {
    visibilite
} from './pages/visibilite';
import {
    cameleon
} from './pages/cameleon';
import {
    devis
} from './pages/devis';
import {
    partenaires
} from './pages/partenaires';
import {
    espace
} from './pages/espace';
import {
    contact
} from './pages/contact';
import {
    singleProduct
} from './pages/single-product';
import {
    toutEvenement
} from './pages/tout-evenement';

//Import components ------------------
import {
    WcBookings
} from './components/wc-bookings';
import {
    menu
} from './components/menu';
import {
    bodyPaddingTop
} from './components/bodyPaddingTop';
import {
    passwordForm
} from './components/password-form';

// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
$(document).ready(function () {
    //document.addEventListener('DOMContentLoaded', function (event) {
    $('.no-js').removeClass('no-js');

    $(document).foundation();

    menu.readyFn();
    bodyPaddingTop.readyFn();
    passwordForm.readyFn();

    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.readyFn();
            break;
        case bodyClassList.contains('page-template-page-securite'):
            securite.readyFn();
        case bodyClassList.contains('single-product'):
            WcBookings.readyFn();
            break;
        case bodyClassList.contains('page-template-page-piste'):
            piste.readyFn();
            break;
        case bodyClassList.contains('page-template-page-piste-karting'):
            pisteKarting.readyFn();
            break;
        case bodyClassList.contains('page-template-page-visibilite'):
            visibilite.readyFn();
            break;
        case bodyClassList.contains('page-template-default'):
            cameleon.readyFn();
            break;
        case bodyClassList.contains('partenaire-template-default'):
            cameleon.readyFn();
            break;
        case bodyClassList.contains('page-template-page-default-karting'):
            cameleon.readyFn();
            break;
        case bodyClassList.contains('page-template-page-cameleon-evenement'):
            cameleon.readyFn();
            break;
        case bodyClassList.contains('page-template-page-devis'):
            devis.readyFn();
            break;
        case bodyClassList.contains('page-template-page-partenaire'):
            partenaires.readyFn();
            break;
        case bodyClassList.contains('page-template-page-espace'):
            espace.readyFn();
            break;
        case bodyClassList.contains('page-template-page-contact'):
            contact.readyFn();
            break;
        case bodyClassList.contains('product-template-default'):
            singleProduct.readyFn();
            break;
        case bodyClassList.contains('page-template-page-evenement'):
            toutEvenement.readyFn();
            break;
    }

});

window.addEventListener('load', function (event) {
    document.body.classList.add('loaded');
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    // Execute AOS
    setTimeout(() => {
        AOS.init({
            once: true,
        });
    }, 100);

});
