// --- Vendors ---
import Glightbox from 'glightbox';
import Swiper, {
    Navigation,
    Pagination
} from 'swiper';
Swiper.use([Navigation, Pagination]);
// --- Components ---
const securite = {
    readyFn: function () {
        let self = this;
        self.revealVideo();
        self.sectionPlan();
    },
    revealVideo: function () {
        const glightbox = Glightbox();
    },

    sectionPlan: function () {

        //swiper
        const swiper = new Swiper('.swiper', {
            // configure Swiper to use modules
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
        });

        let swiperAttr;

        $(".groupe-svg").on("click", function () {
            if ($(this).hasClass("actif") == false) {
                $(".groupe-svg").removeClass("actif");
                $(this).addClass("actif");
                swiperAttr = $(this).attr("data-swiper");
                if (swiperAttr) {
                    $(".wrapper-swiper-texte").removeClass("actif");
                    $(swiperAttr).addClass("actif");
                }
            }
        });


    }
}
export {
    securite
};