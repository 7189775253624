// --- Components ---
const passwordForm = {

    readyFn: function () {
        let self = this;
        self.addSVG();

    },

    addSVG: function () {
    },

}
export {
    passwordForm
};